@charset "utf-8";

/*---------------------------------------
header
----------------------------------------*/
header {
    margin: 0 auto 20px;
    width: 1020px;
    height: 70px;
}

header .logo {
    padding-top: 5px;
}

/*---------------------------------------
.errorArea
----------------------------------------*/

.errorArea {
    margin: 0 auto;
    padding: 70px 0;
    width: 820px;
}

.errorArea .errorBox {
    margin-bottom: 33px;
    padding: 0 0 0 150px;
    background: url(/assets/pc/images/error/error_image.gif) no-repeat 0 40px;
}

/* stylelint-disable-next-line */
page.pageMainte .errorArea .errorBox {
    background: url(/assets/pc/images/error/mainte_image.gif) no-repeat 0 40px;
}

.errorArea .errorBox h1 {
    margin-bottom: 18px;
    font-size: 180%;
    font-weight: bold;
}

.errorArea .errorBox a.linkTop {
    margin-left: 110px;
    padding: 10px 0 10px 32px;
    border-radius: 6px;
    width: 15em;
    font-weight: bold;
    background: #ECF2FB url(/assets/pc/images/icon_arrow_01.png) no-repeat 10px center;
    display: block;
}

.freePhoneArea03 {
    padding: 16px 0;
    border: 1px solid #BBB;
}

.freePhoneArea03 strong {
    font-size: 113%;
    text-align: center;
    display: block;
}

.freePhoneArea03 .freePhoneBox {
    padding: 0 127px;
}

.freePhoneArea03 .freePhoneBox b {
    margin-right: 17px;
    display: inline-block;
}

.freePhoneArea03 .freePhoneBox b .number {
    padding: 4px 0 4px 42px;
    background: url(/assets/pc/images/icon_free_phone.png) no-repeat left center;
    color: #319F2C;
    font-size: 186%;
    font-weight: bold;
    line-height: 1;
    word-wrap: break-word;
    display: block;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .freePhoneArea03 .freePhoneBox b .number {
        font-size: 170%;
    }
}

.freePhoneArea03 .freePhoneBox .poweredBy {
    display: block;
    color: #777;
    font-size: 69%;
    text-align: right;
}

body.pageMainte .errorArea .term {
    margin-bottom: 34px;
    padding: 50px 0;
    font-size: 113%;
    background-color: #ECF2FB;
    text-align: center;
}

/*---------------------------------------
footer
----------------------------------------*/
footer .isoArea {
    margin: 0 auto;
    padding: 20px 0;
    width: 1000px;
}